import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';

const PaymentPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://nowpayments.io/script.js';
        script.onload = () => {
            window.nowpayments.createPayment({
                price_amount: 10,
                price_currency: 'usd',
                pay_currency: 'btc',
                order_id: 71,
                ipn_callback_url: 'https://redismanager.io/api/payment/nowpayments',
            });
        };
        document.body.appendChild(script);
    }, []);

    return (
        <Box>

        </Box>
    );
};

export default PaymentPage;
