import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {Outlet} from "react-router-dom";
import Footer from "../Footer";
import {useSelector} from 'react-redux'

const DefaultLayout = () => {
    const googleLink = useSelector((state) => state.login.googleLink);
    const [googleAuthUrl, setGoogleAuthUrl] = useState("");
    const windowFeatures = "width=500,height=600,left=100,top=100";

    useEffect(() => {
       if (googleLink && googleLink !== googleAuthUrl) {
           setGoogleAuthUrl(googleLink)
           window.open(googleLink, "Google Auth", windowFeatures);
       }
    }, [googleLink]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'space-between',
            height: '100vh',
        }}>
            <Box>
                {/*<Header/>*/}
                <Outlet/>
            </Box>
            <Box>
                <Footer/>
            </Box>
        </Box>
    );
};

export default DefaultLayout;
