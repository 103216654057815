import {SIGNUP_FAIL, SIGNUP_LOADING, SIGNUP_SUCCESS} from "../utilities/Types";

const initialState = {
    error: false,
    loading: false
}

const signup = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUP_SUCCESS:
            return state;
        case SIGNUP_FAIL:
            return {...state, error: action.payload}
        case SIGNUP_LOADING:
            return {...state, loading: action.payload}
        default:
            return state;
    }
};

export default signup;
