import React from 'react';
import Typography from "@mui/material/Typography";
import {Card, CardActions, CardContent, CardHeader} from "@mui/material";
import StyledButton from "../LoadingButton/StyledButton";
import Box from "@mui/material/Box";
import {LANDING_GET_STARTED, LANDING_PER_MONTH, LANDING_POPULAR} from "../../../utilities/TypesTranslation";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const PriceBlock = ({color, name, price, description, isPopular = false}) => {
    const {t,i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
       <Box sx={{
           width: '100%',
           display:'flex',
           justifyContent: 'center',
           alignItems: 'center'
       }}>
           <Card sx={{
               // width: '250px',
               // height: isPopular ? '450px' : '410px',
               textAlign: 'center',
           }}>
               <CardHeader
                   title={name}
                   subheaderTypographyProps={{
                       color: 'white'
                   }}
                   titleTypographyProps={{
                       variant: 'h3',
                       fontWeight: '200',
                       color: 'white'
                   }}
                   subheader={isPopular}
                   sx={{
                       backgroundColor: color
                   }}
               />

               <CardContent style={{
                   marginBottom: '50px'
               }}>
                   <Typography color="textSecondary" variant="h6">
                       {`$ ${price}/${t(LANDING_PER_MONTH)}`}
                   </Typography>

                   <ul style={{textAlign: 'start', paddingLeft:'10px'}}>
                       {
                           description && description.length > 1 && description.map((item, index) => {
                               return (
                                   <li key={index}>
                                       {item}
                                   </li>
                               )
                           })
                       }
                   </ul>

               </CardContent>

               <CardActions>
                   <StyledButton loading={false} onClick={() => {
                       navigate(`/${i18n.language}/payment`)
                   }}>
                       {t(LANDING_GET_STARTED)}
                   </StyledButton>
               </CardActions>
           </Card>
       </Box>
    );
};

export default PriceBlock;
