import {
    SHOW_SNACKBAR_TEXT,
} from "../utilities/Types";

const initialState = {
    snackbarText: '',
}

const shared = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SNACKBAR_TEXT:
            return {...state, snackbarText: action.payload}
        default:
            return state;
    }
};

export default shared;
