import {
    FETCH_RELEASES,
    LOGIN_FAIL,
    LOGIN_GOOGLE_GET_LINK,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGOUT
} from "../utilities/Types";

const initialState = {
    jwt: localStorage.getItem('jwt'),
    error: false,
    googleLink:false,
    loading: false,
    releases: []
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RELEASES:
            return {...state, releases: action.payload};
        case LOGIN_SUCCESS:
            return {...state, jwt: action.payload.token ?? null};
        case LOGIN_FAIL:
            return {...state, error: action.payload}
        case LOGIN_LOADING:
            return {...state, loading: action.payload}
        case LOGOUT:
            return state - 1;
        case LOGIN_GOOGLE_GET_LINK:
            return {...state, googleLink: action.payload}
        default:
            return state;
    }
};

export default login;
