export const CREATE_CONNECT_BUTTON = "common:buttons.connect"
export const CREATE_CONNECT_TITLE = "glossary:createConnect.title"
export const CREATE_CONNECT_SETTINGS_MAIN = "glossary:createConnect.settings.main"
export const CREATE_CONNECT_SETTINGS_SECURITY = "glossary:createConnect.settings.security"
export const CREATE_CONNECT_INPUT_NAME = "common:inputs.nameConnection"
export const CREATE_CONNECT_INPUT_HOST = "common:inputs.host"
export const CREATE_CONNECT_INPUT_PORT = "common:inputs.port"
export const CREATE_CONNECT_INPUT_USERNAME = "common:inputs.username"
export const INPUT_PASSWORD = "common:inputs.password"
export const BUTTON_SELECT_FILE = "common:buttons.selectFile"
export const BUTTON_CREATE = "common:buttons.create"
export const BUTTON_CANCEL = "common:buttons.cancel"
export const BUTTON_TEST_CONNECTION = "common:buttons.testConnection"
export const PLACEHOLDER_PUBLIC_KEY = "common:placeholders.privateKey"
export const PLACEHOLDER_PRIVATE_KEY = "common:placeholders.publicKey"
export const PLACEHOLDER_AUTH_KEY = "common:placeholders.authKey"
export const PLACEHOLDER_SSH_HOST = "common:placeholders.sshHost"
export const PLACEHOLDER_SSH_USER = "common:placeholders.sshUser"
export const LABELS_PUBLIC_KEY = "common:labels.publicKey"
export const LABELS_PRIVATE_KEY = "common:labels.privateKey"
export const LABELS_AUTH_KEY = "common:labels.authKey"
export const LABELS_SSH_HOST = "common:labels.sshHost"
export const LABELS_SSH_USER = "common:labels.sshUser"
export const LABELS_NONE = "common:labels.none"
export const LABELS_SSH_TUNNEL = "common:labels.sshTunnel"
export const LABELS_SSL_TLS = "common:labels.sslTls"
export const CREATE_NEW_KEY = "glossary:insertNewKey.title"
export const ADD_KEY_INPUT_KEY = "common:inputs.key"
export const ADD_KEY_PLACEHOLDER_KEY = "common:placeholders.redisKey"
export const ADD_KEY_PLACEHOLDER_VALUE = "common:placeholders.redisValue"
export const TYPE_KEY_INPUT_KEY = "common:inputs.typeKey"
export const BUTTON_DELETE = "common:buttons.delete"
export const CONFIRM_DELETE_KEY = "glossary:deleteKey.confirm"
export const CONFIRM_DELETE_CONNECTION = "glossary:deleteConnection.confirm"
export const EMPTY_LIST_CONNECTIONS = "glossary:notFound.listConnection"
export const BUTTON_LOGIN = "common:buttons.login"
export const BUTTON_LOGIN_GOOGLE = "common:buttons.loginGoogle"
export const BUTTON_SIGN_UP = "common:buttons.signUp"
export const LABEL_LOGIN = "common:labels.login"
export const LABEL_SIGN_UP = "common:labels.signUp"
export const INPUT_EMAIL = "common:inputs.email"
export const INPUT_PHONE = "common:inputs.phone"
export const INPUT_CONFIRMATION_PASSWORD = "common:inputs.confirmPassword"
export const BUTTON_FORGOT_PASSWORD = "common:buttons.forgotPassword"
export const BUTTON_NOT_HAVE_ACC = "common:buttons.notHaveAccountOnLogin"
export const BUTTON_HAVE_ACC = "common:buttons.haveAccountOnReg"
export const PLAN_DEMO_TITLE = "common:plans.demo.title";
export const PLAN_DEMO_FEATURE_1 = "common:plans.demo.features.1";
export const PLAN_DEMO_FEATURE_2 = "common:plans.demo.features.2";
export const PLAN_DEMO_FEATURE_3 = "common:plans.demo.features.3";
export const PLAN_DEMO_FEATURE_4 = "common:plans.demo.features.4";
export const PLAN_DEMO_FEATURE_5 = "common:plans.demo.features.0";

export const PLAN_MONTHLY_TITLE = "common:plans.monthly.title";
export const PLAN_MONTHLY_FEATURE_1 = "common:plans.monthly.features.1";
export const PLAN_MONTHLY_FEATURE_2 = "common:plans.monthly.features.2";
export const PLAN_MONTHLY_FEATURE_3 = "common:plans.monthly.features.3";
export const PLAN_MONTHLY_FEATURE_4 = "common:plans.monthly.features.4";
export const PLAN_MONTHLY_FEATURE_5 = "common:plans.monthly.features.0";

export const PLAN_YEARLY_TITLE = "common:plans.yearly.title";
export const PLAN_YEARLY_FEATURE_1 = "common:plans.yearly.features.1";
export const PLAN_YEARLY_FEATURE_2 = "common:plans.yearly.features.2";
export const PLAN_YEARLY_FEATURE_3 = "common:plans.yearly.features.3";
export const PLAN_YEARLY_FEATURE_4 = "common:plans.yearly.features.4";
export const PLAN_YEARLY_FEATURE_5 = "common:plans.yearly.features.5";
export const PLAN_YEARLY_FEATURE_6 = "common:plans.yearly.features.0";

export const LANDING_TITLE_APP = "common:landing.title";
export const LANDING_TOOLS = "common:landing.tools";
export const LANDING_SUB_TITLE = "common:landing.subTitle";
export const LANDING_DOWNLOAD_BUTTON = "common:landing.downloadButton";
export const LANDING_DESCRIPTION = "common:landing.description";
export const LANDING_AVAILABLE = "common:landing.available";
export const LANDING_DEMO = "common:landing.demo";
export const LANDING_YEAR = "common:landing.yearly";
export const LANDING_MONTH = "common:landing.monthly";
export const LANDING_POPULAR = "common:landing.popular";
export const LANDING_GET_STARTED = "common:landing.getStarted";
export const LANDING_PER_MONTH = "common:landing.perMonth";
export const ALL_RIGHT = "common:allRight";
export const RELEASES = "common:releases";

