import {
  OPEN_VIEW_ITEM,
  CLOSE_VIEW_ITEM,
  CHANGE_VIEW_ITEM,
  FETCH_LIST_CONNECTIONS_SUCCESS,
  FETCH_LIST_CONNECTIONS_FAILED,
  CONNECTION_LOADING,
  CONNECTION_SUCCESS,
  DB_LOADING,
  DB_LOADING_SUCCESS,
  KEY_LOADING_SUCCESS,
  SHOW_DOWNLOAD_APP_MODAL,
  CLOSE_DOWNLOAD_APP_MODAL,
  SHOW_INSERT_RECORD_MODAL,
  CLOSE_INSERT_RECORD_MODAL, DELETE_KEY_SUCCESS
} from "../utilities/Types";

const initialState = {
  open: true,
  selectedItemId: 0,
  selectedItemName: "",
  openItems: [],
  listConnections: [],
  loading: [],
  dbLoading: false,
  dbList: [],
  dbValueList: [],
  tabValues: [],
  showDownloadAppModal: false,
  showInsertRecordModal: false,
}

const menu = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_CONNECTIONS_SUCCESS:
      return {...state, listConnections: action.payload.data}
    case FETCH_LIST_CONNECTIONS_FAILED:
      return {...state, listConnections: []}
    case OPEN_VIEW_ITEM:
      const exist = state.openItems.find(x => x.tabId === action.payload.tabId);

      if (!exist) {
        return {
          ...state,
          openItems: [...state.openItems, action.payload],
          selectedItemId: action.payload.tabId,
          selectedItemName: action.payload.connectionName,
        };
      }

      return {
        ...state,
        selectedItemId: action.payload.tabId,
        selectedItemName: action.payload.connectionName,
      }
    case CHANGE_VIEW_ITEM:
      return {
        ...state,
        selectedItemId: action.payload
      }
    case CLOSE_VIEW_ITEM:
      const newOpenItems = state.openItems.filter(item => item.tabId !== action.payload);
      return {
        ...state,
        openItems: newOpenItems,
        selectedItemId: newOpenItems.length > 0 ? newOpenItems[0].tabId : 0,
        tabValues: state.tabValues.filter(item => item.tabId !== action.payload)
      }
    case CONNECTION_SUCCESS:
      return {
        ...state,
        dbList: [
          ...state.dbList,
          {
            connectionId: action.payload.connectionId, data: action.payload.data.data
          }
        ]
      }
    case CONNECTION_LOADING:
      return {
        ...state,
        loading: [state.loading, action.payload]
      }
    case DB_LOADING:
      return {
        ...state,
        dbLoading: action.payload
      }
    case DB_LOADING_SUCCESS:
      const existDb = state.dbValueList.findIndex(x => x.connectionId === action.payload.connectionId) === -1

      if (!existDb) {
        return {
          ...state,
          dbValueList: [action.payload]
        }
      }

      return {
        ...state,
        dbValueList: [...state.dbValueList, action.payload]
      }
    case KEY_LOADING_SUCCESS:
      const existKey = state.tabValues.findIndex(x => x.tabId === action.payload.tabId) === -1

      if (!existKey) {
        return {
          ...state,
          tabValues: [action.payload]
        }
      }

      return {
        ...state,
        tabValues: [...state.tabValues, action.payload]
      }
    case SHOW_DOWNLOAD_APP_MODAL:
      return {
        ...state,
        showDownloadAppModal: true
      }
    case CLOSE_DOWNLOAD_APP_MODAL:
      return {
        ...state,
        showDownloadAppModal: false
      }
    case SHOW_INSERT_RECORD_MODAL:
      return {
        ...state,
        showInsertRecordModal: action.payload
      }
    case CLOSE_INSERT_RECORD_MODAL:
      return {
        ...state,
        showInsertRecordModal: false
      }
    case DELETE_KEY_SUCCESS:
      console.log('DELETE SUCCESS')

      return {
        ...state,
      }
    default:
      return state;
  }
};

export default menu;
