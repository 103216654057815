import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {useTranslation} from "react-i18next";
import {ALL_RIGHT} from "../../utilities/TypesTranslation";

const Footer = () => {
  const {t} = useTranslation();
  const year = new Date().getFullYear();

  return (
    <Paper sx={{
      bottom: 0,
      width: '100%',
    }} component="footer" square variant="outlined">
      <Box py={2} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
        <Typography alignItems="center" variant="caption">
          © {year} {t(ALL_RIGHT)}
        </Typography>
      </Box>
    </Paper>
  );
}

export default Footer;