import React from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

const MyGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));


const StyledGrid = (props) => {
  return (
    <MyGrid xs={props.xs ?? 12} style={props.style ?? {}}>
      {props.children}
    </MyGrid>
  );
};

export default StyledGrid;
