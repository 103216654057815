import axios from "axios";
import {API_URL} from "./Constants";

export const Post = (url, data) => {
    let apiUrl = API_URL + '/api/' + url
    let form_data = new FormData();

    for (let key in data) {
        form_data.append(key, data[key]);
    }

    const headers = {
        "Content-Type": "multipart/form-data",
        'Authorization': localStorage.getItem('jwt')
    }

    return axios.post(apiUrl, form_data, {
        headers: headers,
    })
}

export const Get = (url, params) => {
    let apiUrl = API_URL + '/api/' + url

    const headers = {
        'Authorization': localStorage.getItem('jwt')
    }

    return axios.get(apiUrl, {...params, headers:headers})
}