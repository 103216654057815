import React from 'react';
import {CONTAINED, NORMAL, PRIMARY} from "../../../utilities/TypesStyle";
import {LoadingButton} from "@mui/lab";

const StyledButton = ({children, loading, onClick, variant = CONTAINED, color = PRIMARY, style = {}}) => {
  return (
    <LoadingButton
      type="submit"
      margin={NORMAL}
      loading={loading ? loading : undefined}
      onClick={onClick}
      fullWidth
      variant={variant}
      color={color}
      style={style}
    >
      {children}
    </LoadingButton>
  );
};

export default StyledButton;
