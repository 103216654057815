
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGOUT = 'LOGOUT';

export const LOGIN_GOOGLE_GET_LINK = 'LOGIN_GOOGLE_GET_LINK';


export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export const OPEN_VIEW_ITEM = 'OPEN_VIEW_ITEM';
export const CLOSE_VIEW_ITEM = 'CLOSE_VIEW_ITEM';
export const CHANGE_VIEW_ITEM = 'CHANGE_VIEW_ITEM';
export const FETCH_LIST_CONNECTIONS_SUCCESS = 'FETCH_LIST_CONNECTIONS_SUCCESS';
export const FETCH_LIST_CONNECTIONS_FAILED = 'FETCH_LIST_CONNECTIONS_FAILED';
export const SHOW_SNACKBAR_TEXT = 'SHOW_SNACKBAR_TEXT';
export const CONNECTION_SUCCESS = 'CONNECTION_SUCCESS';
export const CONNECTION_FAILED = 'CONNECTION_FAILED';
export const CONNECTION_LOADING = 'CONNECTION_LOADING';
export const CONNECTION_DELETE = 'CONNECTION_DELETE';
export const CONNECTION_DELETE_FAILED = 'CONNECTION_DELETE_FAILED';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DB_LOADING = 'DB_LOADING';
export const DB_LOADING_SUCCESS = 'DB_LOADING_SUCCESS';
export const DB_LOADING_FAILED = 'DB_LOADING_FAILED';
export const KEY_LOADING = 'KEY_LOADING';
export const KEY_LOADING_SUCCESS = 'KEY_LOADING_SUCCESS';
export const KEY_LOADING_FAILED = 'KEY_LOADING_FAILED';
export const CREATE_CONNECT_LOADING = 'CREATE_CONNECT_LOADING';
export const CREATE_CONNECT_SUCCESS = 'CREATE_CONNECT_SUCCESS';
export const CREATE_CONNECT_FAILED = 'CREATE_CONNECT_FAILED';
export const SHOW_DOWNLOAD_APP_MODAL = 'SHOW_DOWNLOAD_APP_MODAL';
export const CLOSE_DOWNLOAD_APP_MODAL = 'CLOSE_DOWNLOAD_APP_MODAL';
export const SHOW_INSERT_RECORD_MODAL = 'SHOW_INSERT_RECORD_MODAL';
export const CLOSE_INSERT_RECORD_MODAL = 'CLOSE_INSERT_RECORD_MODAL';
export const SET_KEY_LOADING = 'SET_KEY_LOADING';
export const SET_KEY_LOADING_SUCCESS = 'SET_KEY_LOADING_SUCCESS';
export const SET_KEY_LOADING_FAILED = 'SET_KEY_LOADING_FAILED';
export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS';
export const FETCH_RELEASES = 'FETCH_RELEASES';
