export const rconnTheme = (prefersDarkMode) => {
  return {
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: prefersDarkMode ? '#ffffff' : '#100f0f',
        light: prefersDarkMode ? '#ffffff' : '#373737',
        dark: prefersDarkMode ? '#ffffff' : '#0e0d0d',
        contrastText: prefersDarkMode ? 'rgba(0, 0, 0, 0.87)' : '#fff'
      },
      //
      secondary: {
        main: '#852F2B',
        light: '#B63D34',
        dark: '#5A201F',
      },
      info: {
        main: '#0288d1',
      },
      text: {
        primary: prefersDarkMode ? '#fff' : 'rgba(0,0,0,0.87)',
        secondary: prefersDarkMode ? 'rgba(126,124,124,0.7)' : 'rgba(0,0,0,0.7)',
      },
      divider: '#A41E11',
      neutral: {
        main: '#fdb90e',
        contrastText: '#fff'
      },
      background: {
        default: prefersDarkMode ? '#1e1e1e' : '#fff',
        paper: prefersDarkMode ? '#1e1e1e' : '#fff',
      },
      json: {
        scheme: 'rconn',
        base00: prefersDarkMode ? '#1e1e1e' : '#ffffff',
        base01: '#B63D34', // ?
        base02: '#464b50', // ?
        base03: prefersDarkMode ? '#fff' : '#464b50', // Count keys
        base04: '#838184', // ?
        base05: '#a7a7a7', // ?
        base06: '#c3c3c3', // B63D34
        base07: '#ffffff', // ?
        base08: '#cf6a4c', // ?
        base09: '#cda869', // ?
        base0A: '#f9ee98', // ?
        base0B: prefersDarkMode ? '#fc4538' : '#bb1d12', // Text Value
        base0C: '#afc4db', // ?
        base0D:  prefersDarkMode ? '#fff' : '#1e1e1e', // Text Key
        base0E: '#9b859d', // ?
        base0F: '#0288d1' // ?
      }
    },
    typography: {
      fontFamily: [
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      button: {
        fontSize: '0.7rem',
      },
      caption: {
        fontSize: '0.6rem',
        fontWeight: 400,
      },
      body1: {
        fontSize: '0.8rem',
      },

      subtitle1: {
        fontSize: '0.9rem',
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 0
          },
        }
      }
    }
  }
}