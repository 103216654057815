import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import StyledGrid from "../../components/UI/Grid/StyledGrid";
import {APP_NAME} from "../../utilities/Constants";
import StyledButton from "../../components/UI/LoadingButton/StyledButton";
import {OUTLINED, SECONDARY} from "../../utilities/TypesStyle";
import Box from "@mui/material/Box";
import rocket from '../../newRocket.png';
import {List, ListItem, ListItemText} from "@mui/material";
import PriceBlock from "../../components/UI/PriceBlock/PriceBlock";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
    LANDING_AVAILABLE, LANDING_DEMO,
    LANDING_DESCRIPTION,
    LANDING_DOWNLOAD_BUTTON, LANDING_MONTH, LANDING_POPULAR,
    LANDING_SUB_TITLE,
    LANDING_TITLE_APP, LANDING_TOOLS, LANDING_YEAR,
    PLAN_DEMO_FEATURE_1,
    PLAN_DEMO_FEATURE_2,
    PLAN_DEMO_FEATURE_3,
    PLAN_DEMO_FEATURE_4, PLAN_DEMO_FEATURE_5,
    PLAN_MONTHLY_FEATURE_1,
    PLAN_MONTHLY_FEATURE_2,
    PLAN_MONTHLY_FEATURE_3,
    PLAN_MONTHLY_FEATURE_4,
    PLAN_MONTHLY_FEATURE_5,
    PLAN_YEARLY_FEATURE_1,
    PLAN_YEARLY_FEATURE_2,
    PLAN_YEARLY_FEATURE_3,
    PLAN_YEARLY_FEATURE_4,
    PLAN_YEARLY_FEATURE_5,
    PLAN_YEARLY_FEATURE_6, RELEASES,
} from "../../utilities/TypesTranslation";
import {fetchDownloadApp} from "../../actions/auth";

const LandingWidget = () => {
    const {t,i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleDownload = () => {
        dispatch(fetchDownloadApp);
    }

    const demo = [
        t(PLAN_DEMO_FEATURE_1),
        t(PLAN_DEMO_FEATURE_2),
        t(PLAN_DEMO_FEATURE_3),
        t(PLAN_DEMO_FEATURE_4),
        t(PLAN_DEMO_FEATURE_5),
    ];

    const monthly = [
        t(PLAN_MONTHLY_FEATURE_1),
        t(PLAN_MONTHLY_FEATURE_2),
        t(PLAN_MONTHLY_FEATURE_3),
        t(PLAN_MONTHLY_FEATURE_4),
        t(PLAN_MONTHLY_FEATURE_5),
    ];


    const yearly = [
        t(PLAN_YEARLY_FEATURE_1),
        t(PLAN_YEARLY_FEATURE_2),
        t(PLAN_YEARLY_FEATURE_3),
        t(PLAN_YEARLY_FEATURE_4),
        t(PLAN_YEARLY_FEATURE_5),
        t(PLAN_YEARLY_FEATURE_6),
    ];

    return (
        <div>
            <Grid container style={{
                justifyContent: 'center',
            }}>
                <StyledGrid style={{justifyContent: 'center'}}>
                    <Typography sx={{mt: 2}} variant="overline" component="div" color="secondary.light">
                        {t(LANDING_TOOLS)}
                    </Typography>
                </StyledGrid>

                <StyledGrid style={{
                    justifyContent: 'center',
                    textAlign:'center',
                    marginTop: '60px'
                }}>
                    <Typography variant="h1" component="div" color="secondary.light">
                        {APP_NAME}
                    </Typography>
                </StyledGrid>

                <StyledGrid style={{
                    justifyContent: 'center',
                    textAlign:'center',
                    marginTop: '10px'
                }}>
                    <Typography variant="h1" color={'primary'}>
                        {t(LANDING_TITLE_APP)}
                    </Typography>
                </StyledGrid>


                <StyledGrid style={{
                    justifyContent: 'center',
                    marginTop: '40px',
                    padding:'15px',
                    textAlign:'center',
                }}>
                    <Typography variant="h4" style={{fontWeight: 200}} color={'primary'}>
                        {t(LANDING_SUB_TITLE)}
                    </Typography>
                </StyledGrid>

                <StyledGrid>
                    <Box style={{
                        width:'100%',
                        marginTop: '30px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <StyledButton
                            color={SECONDARY}
                            loading={false}
                            style={{maxWidth:'300px'}}
                            onClick={handleDownload}
                        >
                            {t(LANDING_DOWNLOAD_BUTTON)}
                        </StyledButton>

                        <StyledButton
                            style={{marginLeft: '5px', maxWidth:'300px'}}
                            color={'primary'}
                            variant={OUTLINED}
                            loading={false}
                            onClick={() => {
                                navigate(`/${i18n.language}/releases`)
                            }}
                        >
                            {t(RELEASES)}
                        </StyledButton>
                    </Box>
                </StyledGrid>

                <StyledGrid>
                    <Box style={{
                        width:'100%',
                        marginTop: '30px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <Box>
                            <img width={'550px'} src={rocket}/>
                        </Box>
                        <Box style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            maxWidth: '500px',
                        }}>
                            <Typography variant="subtitle1" style={{fontWeight: 300}}>
                                {t(LANDING_DESCRIPTION)}
                            </Typography>

                            <List sx={{
                                width: '100%',
                                marginTop:'15px'
                            }}>
                                <Typography variant="subtitle1" style={{fontWeight: 300}}>
                                    {t(LANDING_AVAILABLE)}
                                </Typography>
                                <ListItem>
                                    <Box sx={{
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'secondary.light',
                                        borderRadius: '50%',
                                        marginRight:'5px'
                                    }}>
                                    </Box>
                                    <ListItemText primary="Linux"/>
                                </ListItem>
                                <ListItem>
                                    <Box sx={{
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'secondary.light',
                                        borderRadius: '50%',
                                        marginRight:'5px'
                                    }}>
                                    </Box>
                                    <ListItemText primary="Mac"/>
                                </ListItem>
                                <ListItem>
                                    <Box sx={{
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'secondary.light',
                                        borderRadius: '50%',
                                        marginRight:'5px'
                                    }}>
                                    </Box>
                                    <ListItemText primary="Windows"/>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </StyledGrid>

                <div style={{display:'flex'}}>
                    <StyledGrid xs={4}>
                        <PriceBlock color={'secondary.dark'} key={1} description={demo} name={t(LANDING_DEMO)} price={0} />
                    </StyledGrid>
                    <StyledGrid xs={4}>
                        <PriceBlock color={'secondary.light'} key={2} description={yearly} name={t(LANDING_YEAR)} price={4.99} isPopular={t(LANDING_POPULAR)}/>
                    </StyledGrid>
                    <StyledGrid xs={4}>
                        <PriceBlock color={'secondary.main'} key={3} description={monthly} name={t(LANDING_MONTH)} price={9.99} />
                    </StyledGrid>
                </div>
            </Grid>
        </div>
    );
};

export default LandingWidget;
