import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Landing from "../pages/Landing";
import DefaultLayout from "../components/Layouts/DefaultLayout";
import NotFound from "../pages/NotFound/NotFound";
import {useTranslation} from 'react-i18next';
import Releases from "../pages/Releases";
import PaymentPage from "../components/Payment";

const AppRoutes = () => {
    const [language, setLanguage] = useState(null);
    const location = useLocation();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const detectLanguage = () => {
            const pathLang = location.pathname.split('/')[1];
            const supportedLangs = ['en', 'ru', 'es'];

            if (pathLang && supportedLangs.includes(pathLang)) {
                return pathLang;
            }

            const userLang = navigator.language.split('-')[0];

            return supportedLangs.includes(userLang) ? userLang : 'en';
        };

        setLanguage(detectLanguage());
    }, [location.pathname]);

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);

    if (!language) {
        return null;
    }

    const shouldRedirect = location.pathname === '/';

    return (
        <>
            {shouldRedirect && <Navigate to={`/${language}`} replace />}

            <Routes>
                <Route element={<DefaultLayout />}>
                    <Route path="/:lang" element={<Landing />} />
                     <Route path="/:lang/releases" element={<Releases />} />
                     <Route path="/:lang/payment" element={<PaymentPage />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default AppRoutes;