import { combineReducers } from 'redux';
import login from "./login";
import signup from "./signup";
import menu from "./menu";
import shared from "./shared";

export default combineReducers({
    login,
    signup,
    menu,
    shared
});