import React from "react";
import AppRoutes from "./routes";
import useMediaQuery from '@mui/material/useMediaQuery';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {BrowserRouter} from 'react-router-dom';
import {rconnTheme} from "./utilities/Theme";
import CssBaseline from "@mui/material/CssBaseline";
import './index.css';

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createTheme(rconnTheme(prefersDarkMode)),
        [prefersDarkMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;