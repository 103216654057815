export const SMALL = "small"
export const NORMAL = "normal"
export const INHERIT = "inherit"
export const H6 = "h6"
export const H5 = "h5"
export const H4 = "h4"
export const H3 = "h3"
export const H2 = "h2"
export const H1 = "h1"
export const STATIC = "static"
export const BODY2 = "body2"
export const CONTAINED = "contained"
export const OUTLINED = "outlined"
export const PRIMARY = "primary"
export const SECONDARY = "secondary"