import React from 'react';
import {default as MuiLink} from "@mui/material/Link/Link";
import {Link} from "react-router-dom";
import {BODY2} from "../../../utilities/TypesStyle";

const StyledLink = ({
                        children,
                        component = Link,
                        variant = BODY2,
                        to = '#',
                        underline = 'always'
                    }) => {
    return (
        <MuiLink underline={underline} component={component} to={to} variant={variant}>
            {children}
        </MuiLink>
    );
};

export default StyledLink;
