import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {fetchReleases} from "../../actions/auth";
import StyledLink from "../../components/UI/Link/StyledLink";
import {RELEASES} from "../../utilities/TypesTranslation";
import {useTranslation} from 'react-i18next';

const Releases = () => {
    const dispatch = useDispatch();
    const releases = useSelector((state) => state.login.releases);
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchReleases());
    }, []);


    return (
        <div style={{
            display:"flex",
            justifyContent:"center",
            flexDirection:"column",
            alignItems:"center",
            marginTop:"30px"
        }}>
            {releases.length > 0 ? releases.map((item,index) => {
                return (<div key={index}>
                    {t(RELEASES)} {item.version}
                    <ul>
                        {item.apps.map((app, index) => {
                            return <li>
                                <StyledLink to={app.path}>{app.name}</StyledLink>
                            </li>
                        })}
                    </ul>
                </div>)
            }) : "Waiting release"}
        </div>
    );
};

export default Releases;
