import {Get, Post} from "../utilities/Api";
import {
    FETCH_RELEASES,
    LOGIN_FAIL, LOGIN_GOOGLE_GET_LINK,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_LOADING,
    SIGNUP_SUCCESS
} from "../utilities/Types";

export const fetchLogin = (email, password) => (dispatch) => {
    dispatch({type:LOGIN_LOADING, payload: true})

    Post('login', {email, password}).then((data) => {
        localStorage.setItem("jwt", data.data.token);
        dispatch({type:LOGIN_LOADING, payload: false})
        dispatch({type:LOGIN_SUCCESS, payload: data.data})
    }).catch((err) => {
        dispatch({type:LOGIN_LOADING, payload: false})

        let errorText = err.response.statusText;

        switch (err.response.status) {
            case 422:
                errorText = 'Incorrect email or password';
                break
            default:
                break
        }

        dispatch({type:LOGIN_FAIL, payload:errorText})
    })
}

export const fetchLoginGoogle = () => (dispatch) => {
    dispatch({type:LOGIN_LOADING, payload: true})

    Get('login/google').then((data) => {
        console.log('DATAA ', data)

        dispatch({type:LOGIN_GOOGLE_GET_LINK, payload: data.data.redirect_url})
        dispatch({type:LOGIN_LOADING, payload: false})
    }).catch((err) => {
        console.log('errerrerrerr ', err)
        dispatch({type:LOGIN_LOADING, payload: false})
        dispatch({type:LOGIN_FAIL, payload:err.response.statusText})
    })
}

export const fetchSignup = (email, phone, password, password_confirmation) => (dispatch) => {
    dispatch({type:SIGNUP_LOADING, payload: true})

    Post('register', {email,phone, password, password_confirmation}).then((data) => {
        localStorage.setItem("jwt", data.data.token);
        dispatch({type:SIGNUP_LOADING, payload: false})
        dispatch({type:SIGNUP_SUCCESS, payload: ''})
        dispatch({type:LOGIN_SUCCESS, payload: data.data})
    }).catch((err) => {
        console.log(err)
        dispatch({type:SIGNUP_LOADING, payload: false})

        dispatch({type:SIGNUP_FAIL, payload:err.response.data.message})
    })
}

export const fetchDownloadApp = () => {
    Get('app/download', {responseType:"blob"}).then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const contentDisposition = response.headers['content-disposition'];
        a.download = contentDisposition.split('filename=')[1].split(';')[0];
        document.body.appendChild(a);

        // Программно нажимаем на ссылку для скачивания
        a.click();

        // Удаляем ссылку и элемент <a> после скачивания
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }).catch((err) => {
        console.log(err)
    })
}

export const fetchReleases = () => (dispatch) => {
    Get("app/releases").then((response) => {
        dispatch({type:FETCH_RELEASES, payload: response.data})
    }).catch((err) => {
        console.log(err)
    })
}