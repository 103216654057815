import * as React from 'react';
import LandingWidget from "../../widgets/Landing/Landing";

const Landing = () => {
    return (
        <LandingWidget />
    );
};

export default Landing;
