import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import 'typeface-roboto';

import './utilities/i18n'
import {store} from "./store";
import App from "./App";

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <Provider store={store}>
    <App/>
  </Provider>
);

reportWebVitals();